@import "styles/variaveis";
@import "styles/breakpoints";

.textEditor {

    margin: auto;
    background-color: white;
    height: 25em;
    text-align: left;
    width: 98%;
    margin-bottom: 5em;
}

.alterar {

    background-color: $background-color;
    margin: auto;
    left: auto;
    width: 100%;
    height: 105em;

    &__form{
        
        margin: 4em auto auto auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__text {
            
            width: 70%;
            left: 50%;
        }

        &__foto {

            width: 50%;
            
            &__imagem {
                align-items: center;
                text-align: center;
                display: flex;
                flex-direction: column;
                border-radius: 10px;
                max-height: 350px;
                margin: auto;
                left: auto;
            }
            &__input{

                display: none;
                
                &__label {
                    padding: 10px 10px;
                    width: 100%;
                    color: $blue-mid;
                    font-weight: bold;
                    font-size: 1.9em;
                    text-align: center;
                    display: block;
                    margin-top: 30px;
                    cursor: pointer;
                    margin-bottom: 10px;
                }
            }
        }
    }
}