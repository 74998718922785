@import "styles/variaveis";
@import "styles/breakpoints";

.botaoLink{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-decoration: none;  
    font-weight: bold;
    font-size: 1.5em;
    padding: 20px 20px 20px 20px;
    white-space: nowrap;
    color: $white;
    background-color: $blue-mid;

    @media (max-height: 769px) {
      width: 25vh;
    }
    &__cadastrar {
      border-radius: 8px;
    }
    &__voltar {
      color: black;
    }
    &__excluir {
      background-color: $red-dark;
      align-items: center;
      display: flex;
      width: 80%;
    }
    &__icon {
      background-color: $blue-mid;
      align-items: center;
      display: flex;
    }
    &__box {
      display: flex;
    }
  }
  