@import "styles/variaveis";

.funcionario {
    padding-top: 0.1em;
    margin: auto;
    box-shadow: 0 0 2em $grey;
    width: 80%;
    /* Mobile */
    @media (max-width: 992px) {
        padding: 0px;
        margin: 0px;
    }
}

.funcionario__lista {
    width: 100%;
    height: auto;
    margin-top: 3em;
    padding-bottom: 4em;
    align-items: center;
    display: flex;
    flex-direction: column;
}


.first {
    width: 80%;
    height: auto;
    margin: auto;
    left: auto;
    margin-bottom: 4vh;

    @media (max-height: 769px) {
        margin-bottom: 14vh;
    }
    @media (max-width: 1601px) {
        width: 100%;
    }
    @media (max-width: 992px) {
        padding-left: 1.4vh;
        height: 167vh;
    }
    &__box {
        width: 25%;
        min-height: 39vh;
        float: left;
        flex-direction: column;
        display: flex;
        align-items: center;
        margin-bottom: 2vh;
        margin-left: 4vh;
    }
    &__second {
        
        &__box {
            background-color: $middle-white;
            width: 100%;
            min-height: 39vh;
            box-shadow: 0.40em 0.40em 0.50em $grey;
            float: left;
            flex-direction: column;
            display: flex;
            align-items: center;
    
            @media (max-width: 992px) {
                width: 100%;
                margin: 0px;
                &__image { 
    
                }
            }
    
            &__image {
                margin: auto;
                width: 100%;
                max-height: 30vh;
                align-items: center;
                text-align: center;
    
                @media (min-width: 1200px) and (max-width: 1399px) and (max-height: 550px){
                    width: 70vh;
                    max-height: 50vh;
                }
                @media (min-width: 1200px) and (max-width: 1399px) and  (min-height: 600px) and (max-height: 750px){
                    width: 48vh;
                    max-height: 30vh;
                }
                @media (min-width: 1200px) and (max-width: 1399px) and (min-height:700px) and (max-height: 810px){
                    width: 38vh;
                    max-height: 30vh;
                }
                @media (min-width: 1200px) and (max-width: 1399px) and (min-height: 860px) and (max-height: 1224px) {
                    width: 35vh;
                    max-height: 30vh;
                }
                @media (min-width: 1200px) and (max-width: 1399px) and (min-height: 1025px) {
                    width: 41vh;
                }
            }
        }
    }

}