@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Ubuntu:wght@300&display=swap');

@import-normalize;

body {
  background-color: #f6f6f6;
}

* {
  box-sizing: border-box;
  font-family: 'Ubuntu', sans-serif;
}

#root > main.customContainer {
  display: flex;
  min-height: 100vh;
  position: relative;
  flex-direction: column;  
  flex: 1;
}