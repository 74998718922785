@import "styles/variaveis";
@import "styles/breakpoints";

.bg {
  background-color: $blue-theme;
  /* Mobile */
  @media (max-width: 992px) {

  }
}

.box {
  padding-top: 2.2em;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* Mobile */
  @media (max-width: 992px) {
    padding-top: 0.2em;
  }
}

.footer {
  width: 100%;
  height: auto;
  margin-top: 1em;
  padding-top: 3em;
  padding-bottom: 6em;

  align-items: center;
  display: flex;
  flex-direction: column;

  /* Mobile */
  @media (max-width: 992px) {
    
  }

  &__copyright {
    box-shadow: 0 0 0.2em black;
    background-color: $blue-dark;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    @media (max-width: 992px) {
      text-align: center;
    }
  }
}

.first {
  width: 70%;
  height: 23vh;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1440px) {
    width: 100%;
  }

  &__box {
      width: 27.5%;
      height: 23vh;
      float: left;
      flex-direction: column;
      display: flex;
      align-items: center;
      padding: 1.5vh;
    
      /* Mobile */
      @media (max-width: 992px) {
        padding: 0;
        width: 100%;
      }
  }
  &__whitebox {
      background-image: url('../../assets/img/tech-bg.jpg');
      background-size: cover;
      text-align: center;
      box-shadow: 0 0 0.5em black;
      width: 45%;
      height: 23vh;
      float: left;
      flex-direction: column;
      display: flex;
      align-items: center;
      border-radius: 5px;
      background-color: $white;
      padding: 5vh;
      
      /* Mobile */
      @media (max-width: 992px) {
        width: 0px;
        height: 0px;
        visibility: hidden;
        padding: 0px;
      }

      @media (min-width:993px) and (max-width: 1440px) and (max-height:750px) {
        height: 50vh;
      }
      &__img {
        width: 420px;
        @media (max-width: 1440px) {
          width: 350px;
        }
      }
  }
  
}
