@import "styles/variaveis";

.menu {
    width: 100% !important;
    height: 50px;
    color: white !important;
    font-weight: bold;
    padding-left: 5em;

    &__list {
        display: flex;
        text-align: left;
        align-items: left;
        color: $white;
        font-weight: bold;
        text-decoration: none;
    }
    &__link {
        color: $white !important;
        list-style-type: none;
        
        &__flex {
            margin-left: 1.2em;
            display: flex;
            flex-direction: column;
            align-items: left;
            text-align: left;
            color: $white !important;
            font-size: 1em;
            text-decoration: none;
            font-weight: bold;
            background-color: none;

            @media (min-width: 993px) and (max-width: 1199px) { 
            font-size: 0.9em;
            }
            @media (max-width: 992px) {
            margin-left: 0;
            }
        }
        &__label {
            color: $white;
            font-size: 0px;
            text-align: center;
            align-items: center;
            font-weight: bold;
            display: none;
            visibility: hidden;
        }
    }
}