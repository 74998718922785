@import "styles/variaveis";
@import "styles/breakpoints";

.messageAbsolute {
    text-align: center;
    align-items: center;
    color: $dark;
    padding: 1px 3px 1px 3px;
    color: $dark;
    font-weight: bold;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    &__error{
        background-color: #f76f6f;
    }
    &__success {
        background-color: rgb(151, 255, 151);
    }
}