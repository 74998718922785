@import "styles/variaveis";
@import "styles/breakpoints";

.excluir {
    color: $dark;
    height: 70vh;
    width: 70vh;
    margin: 7em auto auto auto;
}

.box {
    margin-left: 2.5em;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
}

.botaoExcluir {
    align-items: center;
    text-align: center;
    color: $black;
    background-color: $middle-white;
    border: none;
    font-size: 1.8em;
    font-weight: bold;
    width: 90%;
    padding: 20px 10px 25px 10px;
    margin: 0.2em auto auto 1vh;
    left: auto;
  
    text-decoration: none;  
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    flex-wrap: nowrap;
}