@import "styles/variaveis";
@import "styles/breakpoints";

.botao {
  align-items: center;
  text-align: center;
  color: white;
  border: none;
  font-size: 1.5em;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
  padding: 20px 10px 25px 10px;
  
  @media screen and (min-width: 1024px) {
    font-size: 1.8rem;
  }
  @media screen and (max-height: 480px) {
    padding-top: 2vh;
  }

  &__default {
    background-color: $green;
    width: 80%;
  }
  &__blue {
    background-color: $blue-mid;
    width: 40%; 
  }

}

