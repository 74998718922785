@import "styles/variaveis";
@import "styles/breakpoints";

.header {
    align-items: center;
    text-align: center;
    background-image: url("/assets/img/cappelletto.png");
    background-position: left center;
    display: flex;
    height: 100px;
    border-bottom: 1px solid $white; 
    width: 100%;
    position: absolute;    

    /* Mobile */
    @media (max-width: $mobile) {
      margin-top: 12em;
      height: 12em;
    }
    @media (max-width: $desktop_xsm) {
      padding: 0;
    }
    @media (min-width: $desktop_xsm) {
      padding: 0;
      background-image: none;
    }
    @media (min-width: $desktop_xsm) and (max-width: $desktop_sm) {
      padding: 0;
    }
    @media (min-width: $desktop_sm) and (max-width: $desktop_md) {
      padding: 0;
    }
    @media (min-width:1400px) and (max-width:1500px){
      padding-top: 1em;
      height: 80px;
    }
    
    &__logo{
      margin-top: 5px;
      max-width: 35vh;
      margin-left: 45vh;
      
      @media (min-width: 992px) and (max-width: 1199px) {
        margin-left: 4vh;
      }
      @media (min-width: 1200px) and (max-width: 1399px) {
        margin-left: 18vh;
      }
      @media (min-width: 1400px) and (max-width: 1700px) {
        margin-left: 35vh;
      }
      @media (min-width: 1200px) and (max-width: 1399px) and (min-height:600px) and (max-height: 700px) {
        margin-left: 30vh;
      }
      @media (max-width: $mobile) {
        padding-right: 0px;
        padding-left: 0px;
        margin: 0px;
        width: 100%;
        margin-left: 0px;
        margin-top: 0px;
        max-width: 1em;
      }
    }
   
    &__local {
      width: 255px;
      margin-left: 3.5em;
      margin-top: 0.5vh;
      text-align: right;
      font-size: 15px;
      &__img {
        width: 50px;
        height: 50px;
        border: 1px solid $blue-mid;
        float: left;
        background-image: url("/assets/img/cappelleto-logo.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 60%;
      }
    }
    &__contato {
      width: 290px;
      margin-left: 7.5em;
      margin-top: 0.5vh;
      text-align: right;
      font-size: 15px;
      &__fone {
        color: $dark;
        font-weight: bold;
        text-align: right;
        float: right;
        margin-top: 7px;
        margin-bottom: 5px;
      }
      &__email {
        color: $darkest-grey;
        text-align: right;
        float: right;
        margin: 0;
      }
      &__img {
        width: 50px;
        height: 50px;
        border: 1px solid $blue-mid;
        float: left;
        background-image: url("/assets/img/contato.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 80%;
      }
    }
    &__text {
      color: $white;
      font-size: 1rem;
      font-weight: bold;
      padding-left: $padding-horizontal-mobile;
      @media (min-width: 1024px) {
        margin-left: 2%;
      }
    }
}