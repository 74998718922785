@import "styles/variaveis";
@import "styles/breakpoints";

.login {
    background-color: $light-grey;
    width: 100%;
    left: auto;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 150px;
    &__input {
        margin-top: 10px;
        width: 85%;
        height: 50px;
        text-align: center;
        font-size: 1.4em;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid $grey;
    }
    &__img {
        margin-top: 1vh;
        width: 300px;
    }
    &__titulo {
        font-size: 1.7em;
        color: $black;
        margin-top: 0px;
        margin-bottom: 30px;
    }
    &__botao {
        margin-top: 0.5em;
        align-items: center;
        text-align: center;
        color: $white;
        background: $blue-dark;
        border: none;
        font-size: 1.5em;
        font-weight: bold;
        width: 85%;
        padding: 10px 10px 10px 10px;
    }
    &__cadastrar {
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        text-decoration: none;
        font-weight: bold;
        font-size: 1.3rem;
        color: $blue;
    }
    &__cadastrar__red {
        color: red;
    }
    &__cadastrar__green {
        color: $blue-mid;
    }
    @media screen and (max-height: 480px) {
        &__titulo {
            font-size: 1.2em;
            color: $black;
            margin-top: 0px;
            margin-bottom: 0px;
        }
        &__input {
            margin-top: 2px;
        }
        &__img {
            margin-top: 0vh;
            width: 35px;
        }
        &__cadastrar {
            margin-top: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            text-decoration: none;
            font-weight: bold;
            font-size: 1.3rem;
            color: $blue;
        }
        &__cadastrar__red {
            color: red;
        }
        &__cadastrar__green {
            color: green;
        }
    }
  
}