@import 'styles/variaveis';

.inicio {
/* Mobile */
  @media (max-width: 992px) {
    width: 100%;
    height:35vh;
    &__video {
      width: 100%;
      height: 35vh;
    }
  }
  &__video {
    width: 100%;
    height: 90vh;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -150px;
    
    &__text {
      top: -90px;
      position: relative;
      color: white;
      text-align: left;
      text-shadow: 1px 1px 8px rgba(0,0,0,0.6);

      &__subtitulo {
        font-size: 1.7em;
        margin-bottom: 0px;
      }
      &__titulo {
        margin-top: 0px;
        font-size: 4em;
      }
    }
    &__play {
      object-fit: cover;
      position: absolute;
      top: -150px;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
  &__img {
    margin-top: 10vh;
    width: 60px;
    @media screen and (min-width:767px){
      width: 100px;
    }
    @media screen and (max-height: 480px){
      width: 50px;
    }
  }
  &__titulo {
    font-size: 1.2em;
    color: $black;
    margin-top: 0px;
    margin-bottom: 10px;
    @media screen and (max-height: 480px){
      font-size: 1em;
      margin-bottom: 0px;
    }
  }
  
}