@import "styles/variaveis";

.admin {
    
    align-items: center;
    text-align: center;

    &__box{
        margin: auto;
        left: auto;
        width: 60%;
        height: auto;
        &__sair {
            background-color: $red-dark;
            margin-top: 10vh;
            margin-left: -60px;
            padding: 3.5vh 3.5vh 3.5vh 3.5vh;
            font-size: 1.2em;
            color: white;
            font-weight: bold;
            border: none;
            border-radius: 20px;
        }
    }
    
}

