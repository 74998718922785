@import "styles/variaveis";
@import "styles/breakpoints";

.alterar {

    background-color: $background-color;
    align-items: center;
    display: flex;
    flex-direction: column;

    &__form{

        margin-top: 2em;
        width: 80%;
        align-items: center;

        &__campos {
            float: left;
            width: 50%;
        }

        &__foto {

            float:left;
            width: 50%;

            &__remover {

                color: $red-dark;
                text-decoration: none;
                text-align: center;
                margin-left: 1em;
            }
            
            &__imagem {
                align-items: center;
                text-align: center;
                display: flex;
                flex-direction: column;
                border-radius: 10px;
                margin: auto;
                left: auto;
                width: 50%;
            }
            &__input{

                display: none;

                &__label {
                    padding: 10px 10px;
                    width: 100%;
                    color: $blue-mid;
                    font-weight: bold;
                    font-size: 1.9em;
                    text-align: center;
                    display: block;
                    cursor: pointer;
                    margin-bottom: 10px;
                }
            }
        }
    }
}