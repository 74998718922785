@import "styles/variaveis";

.alterar {

    background-color: $background-color;
    margin-top: 2em;
    padding-bottom: 10vh;

    &__form {

    }
}