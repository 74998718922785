@import "styles/variaveis";
@import "styles/breakpoints";

.labelComposta {
    display: flex;
    font-size: 1.45em;
    &__label {
        font-weight: bold;
        padding-right: 1.5em;
    }
}
