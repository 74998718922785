@import 'styles/variaveis';
@import 'styles/breakpoints';

.conteudo {
  left: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: left;
  width: 70%;
  height: auto;
  background-color: $white;
  padding-top: 0.01vh;
  padding-bottom: 20vh;
  box-shadow: 0 0 2em $grey;
  /* Mobile */
  @media (max-width: 992px) {
      padding: 0px;
      margin: 0px;
  }

  &__boxImage{ 
    margin: 2em;
    width: 48%;
    border: 1px solid $grey;
    float: left;
  }
  &__descricao {
    width: 46%;
    margin-top: 5vh;
    float: left;
  }
  
}