@import "styles/variaveis";
@import "styles/breakpoints";

.messageCadastrado {
    width: 90%;
    margin-top: 5%;
    margin-left: 5%;
    color: $dark;
    text-align: center;
    align-items: center;
    height: 50vh;
    font-size: 1.5em;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    &__img {
        margin-bottom: 25px;
        width: 125px;
    }
}