@import 'styles/variaveis';

.bg {
    background-color: $blue;
}

.apresentacao {

    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 5em;
    position: relative;

    &__video {

        &__play {
            position: relative;
            margin-top: 2em;     
        }
    }
}

.apresentacao::before {
    content: '';
    background-image: url('../../assets/img/tech-bg.jpg');
    opacity: 0.14;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
}