@import "styles/variaveis";
@import "styles/breakpoints";

.item {
  cursor: pointer;
  transition: .2s ease-in;
  border-bottom: 1px grey solid;
  border-top: 1px grey solid;
  padding-left: 15px;
  &:hover {
    background-color: $grey;
  }
  &__imagem {
    min-width: 240px;
    @media (max-width: $desktop_xsm) {
      width: 100%;
    }
    img {
      border-radius: 8px;
      width: 100%;
    }
  }
  &__descricao {
    display: flex;
    justify-content: space-around;
  }
}