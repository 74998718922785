@import "styles/variaveis";
@import "styles/breakpoints";

.botaoQuadrado{
    align-items: center;
    text-align: center;
    text-decoration: none;  
    float: left;
    width: 29%;
    height: 100px;
    border-radius: 30px;
    background-color: white;
    box-shadow: 2px 2px 2px #d3d3d3;  
    margin-left: 13px;
    margin-top: 33px;
    padding-top: 33px;
    &__titulo {
      color: $black;
      font-weight: bold;
      font-size: 1rem;
      text-align: center;
      align-items: center;
    }
    @media screen and (min-width:1024px) {
      width: 200px;
      height: 200px;
      padding-top: 70px;
      &__titulo {
        color: $black;
        font-weight: bold;
        font-size: 1.2rem;
        align-items: center;
        text-align: center;
      }
    }
    
    &__voltar {
      margin: 5%;
      color: black;
    }
    &__icon {
      text-align: center;
      align-items: center;
    }
    &__img {
      text-align: center;
      width: 80px;
    }
  }
  