@import "styles/variaveis";
@import "styles/breakpoints";

.alterar {
    
    height: 60em;
    background-color: $background-color;

    &__form {

        align-items: center;
        text-align: center;
        display: flex;
        flex-direction: column;

        &__foto {

            margin-top: 2em;
            align-items: center;
            text-align: center;
            display: flex;
            flex-direction: column;
            
            &__imagem {
                text-align: center;
                display: flex;
                flex-direction: column;
                border-radius: 10px;
                max-height: 350px;
                margin: auto;
                left: auto;
            }
            &__input{

                display: none;
                
                &__label {
                    padding: 10px 10px;
                    width: 100%;
                    color: $blue-mid;
                    font-weight: bold;
                    font-size: 1.9em;
                    text-align: center;
                    display: block;
                    margin-top: 30px;
                    cursor: pointer;
                    margin-bottom: 10px;
                }
            }
        }
        &__input{
            display: none;
            &__label {
                padding: 10px 10px;
                width: 100%;
                color: $blue-mid;
                font-weight: bold;
                font-size: 1.9em;
                text-align: center;
                display: block;
                margin-top: 30px;
                cursor: pointer;
                margin-bottom: 10px;
            }
        }
    }
}