@import "styles/variaveis";
@import "styles/breakpoints";

.subtitulo {
    margin-top: 5px;
    
    color: $darkest-grey;
    font-size: 1.2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: inherit;
    
    @media (max-width: 992px) {
        padding: 15px;
        margin: 0px;
    }
    @media screen and (min-width:1024px) {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    @media screen and (min-width:1024px) {

    }

    &__dark {
        color: $darkest-grey;
    }
    &__white {
        color: white;
    }
    &__middle {
        align-items: center;
        text-align: center;
    }
    &__left {
        align-items: left;
        text-align: left;
    }
}