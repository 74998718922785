$padding-horizontal-mobile: 16px;
$padding-horizontal-sm: 150px;
$padding-horizontal-md: 200px;
$padding-horizontal: 190px;
$padding-vertical-mobile: 25px;

$dark: #242536;
$dark-grey: #92929d;
$grey: #e4e4e4;
$black: #4c4d5e;
$light-grey: #f1f1f1;
$lightest-grey: #f3f3f3;
$background-color: #f6f6f6;
$middle-white: #FAFAFA;
$white:#FCFCFC;
$baby-green: rgb(152, 221, 152);
$green: rgb(88, 150, 88);
$blue: #282b57;
$blue-dark: #0345A1;
$blue-theme: #0455b1;
$blue-mid: #156bcc;
$blue-light: #8888FF;
$red: #d73b3b;
$red-dark: #c62a2a;
$red-darkest: #861b1b;
$darkest-grey: #70707b;
$darkest-grey2: #9696a5;
$brown: rgb(126, 67, 40);
$gold: rgb(255, 196, 0);
$dark-gold: rgb(201, 164, 3);