@import "./variaveis";
@import "./breakpoints";

.customContainer {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.titulo {
  color: $dark;
  font-size: 2rem;
  margin-bottom: -5px;
  text-align: center;
  margin-top: 65px;
}

.titulo {
  
  &__center {
    color: $dark;
    font-size: 1.5rem;
    margin-bottom: -5px;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    font-weight: bold;
  }
}

.tituloMiddle {
  color: $red-dark;
  margin-left: $padding-horizontal-mobile;
  font-size: 1.35rem;
  text-align: center;  
  align-items: center;
  display: flex;
}


.tituloPainel {
  color: $dark;
  font-size:1.4rem;
  text-align: center;
}

.tituloLabel {
  color: $dark;
  font-size: 2rem;
  margin-bottom: 15px;
  margin-top: 45px;
  text-align: center;
}

.tituloLista {
  color: $dark;
  font-size: 0.9rem;
  text-align: left;
}

.tituloListaCenter {
  color: $dark;
  padding: 10px;
  font-size: 1.7rem;
  text-align: center;
}

.caixaDeFerramentas {
  align-items: center;
  text-align: center;
  padding: 25px;
  width: 100%;
  border-bottom: 1px solid grey;
}

.label {
  padding-left: $padding-horizontal-mobile;
  color: $black;
  font-size: 1.5rem;
  font-weight: bold;
}

.input {
  margin-left: $padding-horizontal-mobile;
  box-shadow: 0 0 2em $grey;
  border-radius: 2px;
  border-bottom: 1px solid $dark-grey;
  border-top: 1px solid $grey;
  border-left: 1px solid $grey;
  border-right: 1px solid $grey;
  width: 85%;
  height: 50px;
  font-size: 1.4em;
  padding:5px;
}

.input2 {
  margin-left: $padding-horizontal-mobile;
  box-shadow: 0 0 2em $grey;
  border-radius: 2px;
  border-bottom: 1px solid $dark-grey;
  border-top: 1px solid $grey;
  border-left: 1px solid $grey;
  border-right: 1px solid $grey;
  width: 60%;
  height: 50px;
  font-size: 1.4em;
  padding:5px;
}

.inputText {
  margin-left: $padding-horizontal-mobile;
  border-radius: 2px;
  border-bottom: 1px solid $dark-grey;
  border-top: 1px solid $grey;
  border-left: 1px solid $grey;
  border-right: 1px solid $grey;
  width: 97%;
  height: 7em;
  font-size: 1.4em;
  padding:5px;
}

.selCombo {
  margin-left: $padding-horizontal-mobile;
  border-radius: 2px;
  border: 1px solid $dark-grey;
  width: 40%;
  height: 50px;
  font-size: 1.4em;
  padding:5px;
  background-color: $background-color;
}

.boxDefault {
  
  @media screen and (min-width: 1024px) {
    margin: auto;
    left: auto;
    width: 45%;
    overflow-x: hidden;
    overflow: hidden;
  }
}

.boxDefaultHorizontal {
  
  float:left;
  
  @media screen and (min-width: 1024px) {
    margin: auto;
    left: auto;
    width: 50%;
    overflow-x: hidden;
    overflow: hidden;
  }
}

.footer {
  display: flex;
  height: 11vh;
  width: 100%;
  margin-bottom: 100px;
  bottom: 0;

  @media screen and (min-width: 1024px) {
    width: 70%;
    height: 8vh;
    margin-left: 15%;
    margin-right: 30%;
    margin-top: 50px;
  }
}

.buttonsBox {
  display: flex;
  height: 11vh;
  width: 100%;
  margin-bottom: 20px;
  bottom: 0;

  @media screen and (min-width: 1024px) {
    width: 50%;
    height: 8vh;
    margin-left: 30%;
    margin-right: 30%;
  }
}

.footerMosaico {
  display: flex;
  height: 11vh;
  width: 100%;
  margin-bottom: 60px;
  bottom: 0;
  position: fixed;
  @media screen and (min-width: 1024px) {
    height: 0px;
    width: 0px;
    margin-bottom: 0px;
  }
}

.boxBuscador {
  width: 100%;
  height: 50px;
  margin-top: 2.5em;
  margin-bottom: 4em;
  
  @media screen and (min-width: 1024px) {
    width: 100%;
    height: 50px;
    align-items: center;
    text-align: center;
    display: flex;
  }
}

.buscador {
  align-items: center;
  background-color: $white;
  border-radius: 0px;
  display: flex;
  gap: 18px;
  height: 42px;
  justify-content: space-between;
  padding: 25px;
  width: 100%;
  margin-top: 50px;

  @media (max-width: $mobile) {
    width: 100%;
  }

  @media screen and (min-width: 1024px) {
    width: 80vh;
    margin:auto;
  }

  input {
    background-color: white;
    border: none;
    font-size: 1.5rem;
    font-weight: bold;
    outline: none;
    width: 100%;
    &::placeholder {
      color: #4c4d5e;
    }
  }
}

.conteudo {
  padding-left: $padding-horizontal-mobile;
  align-items: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  height: 80vh;
  overflow: scroll;
  @media screen and (min-width: 1024px) {
    width: 55%;
    height: 80vh;
    margin: auto;
    overflow: scroll;
    overflow-x: hidden;
  }
  &__body{
    margin-top: 0px;
  }
  &__descricao {
    margin-top: 15px;
    color: $black;
    font-size: 1.5rem;
    font-weight: bold;
  }
  &__detalhes {
    display: flex;
    justify-content: space-between;
  }
  &__imagem {
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    max-width: 100%;
    max-height: 150px;
    object-fit: contain;
    width: auto;
    height: auto;
    margin-top: 3px;
    margin-bottom: -5px;
    &__link {
      text-decoration: none;
      color: $red-dark;
      font-weight: bold;
      font-size: 1.5em;
      text-align: right;
      margin-top: 15px;
      margin-right: 5%;
      margin-bottom: -10px;
      &__icon{
        text-align: center;
        align-items: center;
        margin-bottom: -10px;
      }
    }
  }
}