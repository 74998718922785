@import "styles/variaveis";
@import "styles/breakpoints";

.botaoTela {
  align-items: center;
  text-decoration: none;  
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  flex-wrap: nowrap;

  font-size: 1.7rem;
  font-weight: bold;

  width: 98%;

  padding-top: 13px;
  padding-bottom: 13px;

  border-bottom: 1px solid $grey;
  border-top: 1px solid $grey;  

  background-color: $middle-white;
  color: $black;
  
  margin: auto;
  
  &__iconBox{
    float: left;
    margin-left: 70%;
    margin-top: -29px;
  }
  &__texto{
    float: left;
  }
  &__box{
    float: left;
    margin-bottom: 5px;
    width: 100%;
  }
  &__img {
    max-width: 30px;
  }
  @media screen and (min-width:1024px) {
    width: 40%;
    &__box{
      margin-bottom: 10px;  
      width: 100%;
    }
  }
  &__icon {
      align-items: left;
      text-align: left;
      display: flex;
      white-space: nowrap;
      margin-left: 0px;
  }
}