@import "styles/variaveis";
@import "styles/breakpoints";

.message {

    text-align: center;
    align-items: center;
    color: $dark;
    padding: 21px 3px 1px 3px;
    color: $dark;
    font-weight: bold;
    font-size: 1.2em;
    width: 100%;

    &__error{
        background-color: #f76f6f;
    }
    &__success {
        background-color: rgb(151, 255, 151);
    }
}