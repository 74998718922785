@import "styles/variaveis";
@import "styles/breakpoints";

.socialHeader {

    background-color: rgb(20, 20, 51);
    height: 2.5em;
    padding-right: 22.3%;

    &__icon {
        float: right;
        color: white;
        margin-right: 10px;
        padding: 4px;
    }
    &__icon:hover {
        color: rgb(51, 82, 167);
    }
    &__icon2 {
        float: right;
        color: white;
        margin-right: 10px;
        padding: 8px 4px 4px 4px;
    }
    &__icon2:hover {
        color: rgb(86, 230, 255);
    }
    &__icon3 {
        float: right;
        color: white;
        margin-right: 10px;
        padding: 7px 10px 4px 4px;
    }
    &__icon3:hover {
        color: rgb(248, 4, 207);
    }

    @media (max-width: $mobile) {
        padding-right: 7.5em;
    }
}