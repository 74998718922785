@import "styles/variaveis";

.trabalhe {
    background-color: $middle-white;
    padding-top: 0.005vh;
    margin: auto;
    box-shadow: 0 0 2em $grey;
    width: 80%;
    height: 120vh;
    /* Mobile */
    @media (max-width: 992px) {
        padding: 0px;
        margin: 0px;
    }
}

.first {
    width: 85%;
    height: auto;
    margin: auto;
    left: auto;

    @media (max-height: 769px) {
        margin-bottom: 14vh;
    }
    @media (max-width: 1601px) {
        width: 100%;
    }
    @media (max-width: 992px) {
        padding-left: 1.4vh;
        height: 167vh;
    }
    
    &__box {
        width: 50%;
        float: left;
        height: 50vh;
        padding-top: 3em;

        @media (max-width: 992px) {
            width: 100%;
            margin: 0px;
        }
        &__trabalhe{
            margin-top: 1.5vh;
            width: 85%;
            height: auto;
            padding: 2.5vh;
            box-shadow: 0 0 2em $grey;
        }
        &__imagem {
            
            margin-left: 3em;
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;

            &__propriedades {
                min-width: 25em;
                max-width: 40em;
                box-shadow: 0.4vh 0.4vh 0.4em $darkest-grey;
                margin-bottom: 1.5em;
            }
        }
    }

}