@import "styles/variaveis";
@import "styles/breakpoints";

.bg {
    background-color: $blue-theme;
}

.box {
    padding-top: 2.2em;
    position: relative;
    display: flex;
    flex-direction: column;

    @media (max-width: 992px) {
        height: 162vh;
    }
}

.box::before {
    content: '';
    background-image: url('../../../assets/img/tech-bg.jpg');
    opacity: 0.14;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
}

.servico {
    width: 100%;
    height: auto;
    margin-top: 1em;
    padding-bottom: 10em;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.first {
    width: 80%;
    height: 23vh;
    margin: 0;
    padding-left: 13vh;

    @media (max-width: 1601px) {
        width: 100%;
    }
    @media (max-width: 992px) {
        padding-left: 2vh;
        padding-right: 2vh;
        margin: 0px;
    }

    &__box {
        width: 27%;
        height: 24vh;
        float: left;
        flex-direction: column;
        display: flex;
        align-items: center;
        box-shadow: 0 0 0.2em black;
        border-radius: 2px;
        margin-top: 3vh;
        margin-left: 2em;
        background-color: $blue-dark;

        @media (max-width: 992px) {
            padding: 0px;
            margin-top: 3vh;
            margin-left: 0;
            width: 100%;
            height: 35vh;
        }

        @media (min-width: 993px) and (max-width: 1200px) {
            height: 40vh;
            margin-left: 0.5em;
            width: 30%;
        }
        @media (min-width: 1200px) and (max-width: 1399px) and (max-height: 500px){
            height: 50vh;
        }
        @media (min-width: 1200px) and (max-width: 1399px) and (min-height: 601px) and (max-height: 800px){
            height: 35vh;
        }
        @media (min-width: 1200px) and (max-width: 1399px) and (min-height: 801px) {
            height: 28vh;
        }
        @media (min-width: 1400px) and (max-width: 1601px) {
            height: 28vh;
        }
    }
}

