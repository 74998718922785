@import "styles/variaveis";
@import "styles/breakpoints";

.descricaoComponent {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin-top: 0px;
    align-items: center;
    padding-left: 1.3em;
    padding-right: 2em;

    &__white {
        color: white;
    }
    &__dark {
        color: $darkest-grey;
    }
    &__center {
        text-align: center;
    }
    &__left {
        text-align: left;
    }
    &__large {
        font-size: 1.7em;  
        @media (max-width: 1024px) {
            font-size: 1.2em;
        }
    }
    &__common {
        font-size: medium;
    }
    &__medium{
        font-size: 2vh;
    }
    &__small {
        font-size: small;
    }
}