@import "styles/variaveis";

.cliente {
    padding-top: 0.01vh;
    margin: auto;
    box-shadow: 0 0 2em $grey;
    width: 80%;
    padding-bottom: 20vh;
    /* Mobile */
    @media (max-width: 992px) {
        padding: 0px;
        margin: 0px;
    }
    &__lista {
        width: 100%;
        height: auto;
        margin: auto;
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    &__first {
        width: 100%;
        align-items: center;
        display: flex;
        padding-left: 15vh;
        flex-wrap: wrap;

        @media (max-height: 769px) {
            margin-bottom: 14vh;
        }
        @media (max-width: 1601px) {
            width: 100%;
        }
        @media (max-width: 992px) {
            padding-left: 1.4vh;
            height: 167vh;
        }
        &__text {
            margin: auto;
            width: 80%;
            text-align: center;
            align-items: center;
            display: flex;
            flex-direction: column;
        }
        &__box {
            width: 30%;
            align-items: center;
            text-align: center;
            margin-left: 2vh;
            margin-top: 3vh;

            @media (max-width: 992px) {
                width: 100%;
                margin: 0px;
                &__image { 
    
                }
            }
            &__image {
                width: 35vh;
                @media (min-width: 1200px) and (max-width: 1399px) and (max-height: 550px){
                    width: 70vh;
                    max-height: 50vh;
                }
                @media (min-width: 1200px) and (max-width: 1399px) and  (min-height: 600px) and (max-height: 750px){
                    width: 48vh;
                    max-height: 30vh;
                }
                @media (min-width: 1200px) and (max-width: 1399px) and (min-height:700px) and (max-height: 810px){
                    width: 38vh;
                    max-height: 30vh;
                }
                @media (min-width: 1200px) and (max-width: 1399px) and (min-height: 860px) and (max-height: 1224px) {
                    width: 35vh;
                    max-height: 30vh;
                }
                @media (min-width: 1200px) and (max-width: 1399px) and (min-height: 1025px) {
                    width: 41vh;
                }
            }
        }
    }
}
