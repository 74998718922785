@import 'styles/variaveis';
@import 'styles/breakpoints';

.conteudo {
  left: auto;
  margin: auto;
  text-align: center;
  align-items: center;
  width: 90%;
  min-height: 120vh;
  background-color: $white;
  padding-top: 0.01vh;
  padding-bottom: 20vh;
  box-shadow: 0 0 2em $grey;

  @media (max-width: 992px) {
    padding: 0px;
    margin: 0px;
  }
  &__box{
    padding-top: 5vh;
    float: left;

    &__image {
      margin-left: 5vh;    
      float: left;
      border-radius: 5px;
      max-width: 30%;

      &__img {
        box-shadow: 0.2vh 0.2vh 0.25em $darkest-grey;
        float: left;
        width: 100%;
        margin-bottom: 1em;
      }
      &__descricao {
        padding-top: 2em;  
      }
    }
    &__descricao {
      float: left;
      margin-top: 1vh;
      margin-left: 15vh;    
      width: 50%;

      &__email {

        float: left;
        text-align: left;
        background-color: $middle-white;
        width: 100%;

        &__form {

          margin-top: 3em;
        }
      }
    }
  }  
}