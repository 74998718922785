@import "styles/variaveis";
@import "styles/breakpoints";

.homePage {
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 28vh;
  margin-top: 3em;

  @media screen and (min-width:1024px) {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    text-align: center;
  }
  &__filtros {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
  }
}