@import "styles/variaveis";
@import "styles/breakpoints";

.alterar {

}

.foto {
    
    &__imagem {
        align-items: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        max-height: 450px;
        margin: auto;
        left: auto;
    }
    &__input{
        display: none;
        &__label {
            padding: 10px 10px;
            width: 100%;
            color: $blue-mid;
            font-weight: bold;
            font-size: 1.9em;
            text-align: center;
            display: block;
            margin-top: 30px;
            cursor: pointer;
            margin-bottom: 10px;
        }
    }
}