@import "styles/variaveis";

.menu {
    margin-top: 11vh;
    padding-top: 1vh;
    padding-left: 15vh !important;
    text-decoration: none;
    width: 100%;
    font-weight: bold;

    &__title {
      margin-left: -13vh;
      @media (min-width: 993px) {
        margin-left: 0vh;
        width: 0px;
        height: 0px;
        visibility: hidden;
      }  
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      margin-top: 23vh;
      padding-left: 0vh;
    }
    @media (min-width:1024px) and (max-width: 1199px){
      margin-top: 16vh;
    }
    @media (min-width: 1200px) and (max-width: 1399px) {
      margin-top: 13vh;
      padding-left:15vh;
    }
    @media (min-width: 1200px) and (max-width: 1399px) and (min-height:799px) and (max-height: 900px){
      margin-top: 13vh;
      padding-left:8vh;
    }
    @media (min-width: 1200px) and (max-width: 1399px) and (min-height:300px) and (max-height: 600px) {
      margin-top: 24vh;
    }
    @media (min-width: 1200px) and (max-width: 1399px) and (min-height:600px) and (max-height: 700px) {
      margin-top: 16vh;
      padding-left: 20vh !important;
    }
    @media (min-width:1400px) and (max-width:1500px){
      margin-top: 15vh;
      padding-left: 25vh;
    }
    @media (min-width:1400px) and (max-width:1600px){
      margin-top: 12vh;
      padding-left: 28vh !important;
    }
    
    &__img {
      text-align: center;
      width: 35px;      
    }
    &__list {
      display: flex;
      text-align: left;
      align-items: left;
      color: $white;
      font-weight: bold;
      text-decoration: none;
    }
    &__link {
      color: $white;
      list-style-type: none;
      
      &__flex {
        margin-left: 2.5em;
        display: flex;
        flex-direction: column;
        align-items: left;
        text-align: left;
        color: $white !important;
        font-size: 1em;
        text-decoration: none;
        font-weight: bold;
        background-color: none;

        @media (min-width: 993px) and (max-width: 1199px) { 
          font-size: 0.9em;
        }
        @media (max-width: 992px) {
          margin-left: 0;
        }
      }
      &__label {
        color: $white;
        font-size: 0px;
        text-align: center;
        align-items: center;
        font-weight: bold;
        display: none;
        visibility: hidden;
      }
    }
}