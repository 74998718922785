@import "styles/variaveis";
@import "styles/breakpoints";

.titulo {
    color: $dark;
    font-size: 1.7rem;
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin-top: 35px;
    
    @media screen and (min-width:1024px) {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 60px;
    }

    &__white {
        color: white;
    }
    &__black {
        color: black;
    }
    &__small {
        font-size: 1.1rem;
    }
    &__large {
        font-size: 2.5rem;
    }
    &__middle {
        font-size: 1.7rem;
    }
    &__align {

        &__left {
            text-align: left;
            align-items: left;
        }
        &__center {
            text-align: center;
            align-items: center;
        }
    }
}