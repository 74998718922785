@import "styles/variaveis";
@import "styles/breakpoints";

.dado {
    height: 80vh;
    
    &__filtros {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 20px;
      width: 100%;
    }
  }