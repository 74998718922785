@import "styles/variaveis";

.institucional {
    padding-top: 0.01vh;
    margin: auto;
    box-shadow: 0 0 2em $grey;
    width: 80%;
    /* Mobile */
    @media (max-width: 992px) {
        padding: 0px;
        margin: 0px;
    }

    &__image {
        width: 100%;
        height: 270px;
        align-items: center;
        text-align: center;
        flex-direction: column;
        display: flex;
        background-image: url('https://www.grupocappelletto.com//src/assets/fotos/institucional/i1.png');
        background-size: 100%;
        background-position: center;
        margin-top: 1vh;
        margin-bottom: -3vh;
        box-shadow: 0.2vh 0.2vh 0.25em $darkest-grey;

        @media (min-width: 1200px) and (max-width: 1399px) and (max-height: 550px){
            width: 70vh;
            max-height: 50vh;
        }
        @media (min-width: 1200px) and (max-width: 1399px) and  (min-height: 600px) and (max-height: 750px){
            width: 48vh;
            max-height: 30vh;
        }
        @media (min-width: 1200px) and (max-width: 1399px) and (min-height:700px) and (max-height: 810px){
            width: 38vh;
            max-height: 30vh;
        }
        @media (min-width: 1200px) and (max-width: 1399px) and (min-height: 860px) and (max-height: 1224px) {
            width: 35vh;
            max-height: 30vh;
        }
        @media (min-width: 1200px) and (max-width: 1399px) and (min-height: 1025px) {
            width: 41vh;
        }
    }
}

.institucional__lista {
    width: 100%;
    height: auto;
    margin-top: 3em;
    padding-bottom: 10em;
    align-items: center;
    display: flex;
    flex-direction: column;
}


.first {
    width: 80%;
    height: auto;
    margin: auto;
    left: auto;
    margin-bottom: 4vh;

    @media (max-height: 769px) {
        margin-bottom: 14vh;
    }
    @media (max-width: 1601px) {
        width: 100%;
    }
    @media (max-width: 992px) {
        padding-left: 1.4vh;
        height: 167vh;
    }
    
    &__box {
        float: left;
        width: 100%;
        @media (max-width: 992px) {
            width: 100%;
            margin: 0px;
            &__image { 

            }
        }
    }

}