@import "styles/variaveis";
@import "styles/breakpoints";

.bg {
  background-color: $dark;
  width: 100%;
  position: fixed;
  bottom: 0;
  /* Mobile */
  @media (max-width: 992px) {

  }
}

.box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* Mobile */
  @media (max-width: 992px) {
    padding-top: 0.2em;
  }
  &__copyright {
    padding: 2.2vh;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    @media (max-width: 992px) {
      text-align: center;
    }
  }
}