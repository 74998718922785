
.box {
    padding-top: 2.5em;
    /* Mobile */
    @media (max-width: 992px) {
        padding: 0px;
        margin: 0px;
    }
}

.sobre {
    width: 100%;
    height: auto;
    margin-top: 3em;
    padding-bottom: 4em;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.first {
    width: 80%;
    height: 57vh;
    margin: 0;
    padding-left: 13vh;
    margin-bottom: 4vh;

    @media (max-height: 769px) {
        margin-bottom: 14vh;
    }
    @media (max-width: 1601px) {
        width: 100%;
    }
    @media (max-width: 992px) {
        padding-left: 1.4vh;
        height: 167vh;
    }
    
    &__box {
        width: 30%;
        max-height: 65vh;
        float: left;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0px;
        overflow: hidden;
        margin-left: 0.5em;

        @media (max-width: 992px) {
            width: 100%;
            margin: 0px;
            &__image { 

            }
        }

        &__image {
            
            width: 41vh;
            max-height: 30vh;
            align-items: center;
            text-align: center;
            flex-direction: column;
            display: flex;
            margin-bottom: 0px;

            @media (min-width: 1200px) and (max-width: 1399px) and (max-height: 550px){
                width: 70vh;
                max-height: 50vh;
            }
            @media (min-width: 1200px) and (max-width: 1399px) and  (min-height: 600px) and (max-height: 750px){
                width: 48vh;
                max-height: 30vh;
            }
            @media (min-width: 1200px) and (max-width: 1399px) and (min-height:700px) and (max-height: 810px){
                width: 38vh;
                max-height: 30vh;
            }
            @media (min-width: 1200px) and (max-width: 1399px) and (min-height: 860px) and (max-height: 1224px) {
                width: 35vh;
                max-height: 30vh;
            }
            @media (min-width: 1200px) and (max-width: 1399px) and (min-height: 1025px) {
                width: 41vh;
            }
        }
    }
}

