@import "styles/variaveis";
@import "styles/breakpoints";

.bg {
    background-color: $dark;

}

.box {
    padding-top: 12vh;
    position: relative;
    display: flex;
    flex-direction: column;
}

.box::before {
    content: '';
    background-image: url('../../../assets/img/cappelletto-trucks.jpg');
    opacity: 0.17;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
}

.dados {
    width: 100%;
    height: auto;
    padding-bottom: 5em;
    align-items: center;
    z-index: 1;
    display: flex;
    flex-direction: column;
}

.first {
    width: 62.8%;
    height: 23vh;
    padding-left: 5vh;
    background-color: white;
    box-shadow: 0 0 0.2em black;

    /* Mobile */
    @media (max-width: 992px) {
        width: 78%;
        height: 85vh;
    }

    @media (min-width: 1230px) and (max-width:1323px) and (max-height: 700px) { 
        height: 40vh;
    }
    @media (min-width: 1230px) and (max-width:1323px) and (min-height: 701px) { 
        width: 70%;
        height: 28vh;
    }
    @media (min-width: 1324px) and (max-width:1500px) { 
        width: 70%;
        height: 30vh;
    }
    &__box {
        margin-top: 3.5vh;
        width: 25%;
        z-index: -3;
        height: 21vh;
        float: left;
        flex-direction: column;
        display: flex;
        align-items: center;
        /* Mobile */
        @media (max-width: 992px) {
            width: 98%;
            margin-top: 0vh;
        }
    }
}

