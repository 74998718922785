@import "styles/variaveis";
@import "styles/breakpoints";

.tituloPequeno {
    
    font-size: 1.3rem;
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-decoration: none;

    /* Mobile */
    @media (max-width: 992px) {
        font-size: 1em;
    }

    @media screen and (min-width:1024px) {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    &__nomarginleft {
        align-items: left;
        text-align: left;
        margin-bottom: 0.2em;
    }
    &__alignleft {
        align-items: left;
        text-align: left;
        margin-left: 2.1em;
        margin-bottom: 0.2em;
    }

    &__aligncenter {
        align-items: center;
        text-align: center;
        margin-bottom: 15px;
    }
    &__maxbottom{
        margin-bottom: 1.5em;
    }
    &__mintop {
        margin-top: 5px;
    }
    &__midtop {
        margin-top: 20px;
    }
    &__white {
        color: white;
    }
    &__white:hover {
        color: black;
    }
    &__dark {
        color: $dark;
    }
}

.tituloPequeno:hover {
    animation: fadeIn 2s;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}