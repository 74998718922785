@import "styles/variaveis";
@import "styles/breakpoints";

.clientes {
    
    background-color: $background-color;
    align-items: center;
    display: flex;
    flex-direction: column;

    &__form {

        margin-top: 4em;
        width: 80%;
        align-items: center;

        &__foto {

            width: 50%;
            float:left;

            &__imagem {
                align-items: center;
                text-align: center;
                display: flex;
                flex-direction: column;
                border-radius: 10px;
                max-height: 350px;
                margin: auto;
                left: auto;
            }
            &__input{
                display: none;
                &__label {
                    padding: 10px 10px;
                    width: 100%;
                    color: $blue-mid;
                    font-weight: bold;
                    font-size: 1.9em;
                    text-align: center;
                    display: block;
                    margin-top: 30px;
                    cursor: pointer;
                    margin-bottom: 10px;
                }
            }
        }
    }
}