@import "styles/variaveis";
@import "styles/breakpoints";

.recuperar {
    margin-top: 60px;
}

.titulo {
    color: $dark;
    font-size: 2rem;
    margin-bottom: 45px;
    text-align: center;
    align-items: center;
    margin-top: 125px;
}