@import "styles/variaveis";

.itens {

    margin-top: 20px;
    display: flex;
    flex-direction: column;
    height: 25em;
    overflow: scroll;
    background-color: $middle-white;

    @media screen and (min-width:1024px) {
        width: 40%;
        overflow-x: hidden;
        margin: auto;
    }
}