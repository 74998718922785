@import 'styles/variaveis';
@import 'styles/breakpoints';

.voltar {
    font-weight: bold;
    align-items: center;
    text-align: center;
    text-decoration: none;  
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    width: 80%;
    background-color: $grey;
    border-top: 1px solid $light-grey;
    padding-bottom: 0.5em;

    &__confirmar {
        margin-top: 15%;
        text-decoration: none;  
        color: $black;
        background-color: $grey;
        border-radius: 8px;
        font-weight: bold;
        border: none;
        @media screen and (max-height:480px){
            margin-top: 1%;
        }
        @media screen and (min-height:740px) and (max-height:843px){
            margin-top: 22%;
        }
        @media screen and (min-height:844px){
            margin-top: 26%;
        }
    }
    &__box {
        display: flex;
    }
}