@import 'styles/variaveis';
@import 'styles/breakpoints';

.voltar {
    font-weight: bold;
    align-items: center;
    text-align: center;
    text-decoration: none;  
    padding: 5px 5px 5px 5px;
    display: flex;
    flex-direction: column;
    font-size: 1.8rem;
    margin-top: 50px;

    &__confirmar {
        margin: 10%;
        text-decoration: none;  
        color: $dark;
        background-color: $baby-green;
        border-radius: 8px;
        font-weight: bold;
        border: none;
        padding: 15px 15px 15px 15px;
    }
    &__box {
        display: flex;
    }
    @media screen and (min-height:1024px) {
        padding: 5px 5px 5px 5px;
        margin: auto;
    }
}