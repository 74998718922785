@import 'styles/variaveis';
.customContainer {
  align-items: center;
  text-align: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  left: auto;
}
.voltar {
  align-items: center;
  text-align: center;
  margin: auto;
  left: auto;
  width: 100%;
  font-size: 2rem;

  button {
    background-color: transparent;
    border: none;
    color: $black;
    cursor: pointer;
    
    font-weight: bold;
  }
}