@import "styles/variaveis";

.itens {
    
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    height: 25em;
    overflow: scroll;
    background-color: $middle-white;

    @media screen and (min-width:1024px) {
        width: 40%;
        overflow-x: hidden;
        margin: auto;
    }
}

table {
    text-align: center;
    font-family: Montserrat, Arial, sans-serif;
    background:#fafafa ;
}

tr:nth-child(even) {
    background:$grey;
}